<template>
	<div class="page">
		<lm-header :scrollTop="scrollTop" :bannerH="bannerH" :navH="navH" replaceHeader />
		<div class="banner" ref="banner">
			<div class="layout content">
				<div class="title">灵妙知识学院</div>
				<div class="button" @click="() => {$router.push('/relation')}">立即体验</div>
			</div>
		</div>
		<div ref="nav" class="navBox" :style="navStyle">
			<div class="nav">
				<div
					ref="nav0"
					class="title"
					:class="{ active: navActive == 0 }"
					@click="navActiveChange(0)"
				>
					数字化系统学院
				</div>
				<div
					ref="nav1"
					class="title"
					:class="{ active: navActive == 1 }"
					@click="navActiveChange(1)"
				>
					行业发展学院
				</div>
				<div class="activebox" :style="activeStyle">
					<div></div>
				</div>
			</div>
		</div>
		<div v-show="this.scrollTop >= this.bannerH" style="width:100px;height:80px;"></div>
		<div v-if="!type" style="margin-top:70px;">
			<div class="main" v-if="navActive == 0">
				<template v-if="article && article.length">
					<div
						class="card"
						v-for="(item, index) in article"
						:key="'article' + index"
						@click="toDetail('article', item)"
					>
						<div class="img">
							<img :src="item.img">
						</div>
						<div class="text">
							<div class="title">{{ item.title }}</div>
							<div class="info">{{ item.contentShort }}</div>
						</div>
					</div>
				</template>
				<div v-else style="color:#666666">暂无内容</div>
				</div>
			<div class="main" v-if="navActive == 1">
				<template v-if="industry && industry.length">
					<div
						class="card"
						v-for="(item, index) in industry"
						:key="'industry' + index"
						@click="toDetail('industry', item)"
					>
						<div class="img">
							<img :src="item.img">
						</div>
						<div class="text">
							<div class="title">{{ item.title }}</div>
							<div class="info">{{ item.contentShort }}</div>
						</div>
					</div>
				</template>
				<div v-else style="color:#666666">暂无内容</div>
			</div>
		</div>
		<div v-show="type" class="articleBox">
			<div class="back" @click="back">
				<svg t="1636098415988" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1313" width="14" height="14" fill=""><path d="M671.968176 911.99957c-12.287381 0-24.576482-4.67206-33.951566-14.047144L286.048434 545.984249c-18.751888-18.719204-18.751888-49.12028 0-67.872168L638.016611 126.111222c18.751888-18.751888 49.12028-18.751888 67.872168 0 18.751888 18.719204 18.751888 49.12028 0 67.872168l-318.016611 318.047574L705.888778 830.047574c18.751888 18.751888 18.751888 49.12028 0 67.872168C696.544658 907.32751 684.255557 911.99957 671.968176 911.99957z" p-id="1314"></path></svg>
				<span>返回</span>
			</div>
			<div class="articleDetail">
				<h1>{{data.title}}</h1>
				<div class="author">{{data.author}}</div>
				<hr>
				<div v-html="data.content"></div>
			</div>
		</div>
		<lm-footer />
	</div>
</template>

<script>
import LmHeader from "@/components/header";
import LmFooter from "@/components/footer";
import axios from 'axios';
export default {
	components: {
		LmHeader,
		LmFooter,
	},
	computed: {
		activeStyle() {
			const style = {};
			style.width = this.navWidth[this.navActive] + "px";
			style.left = this.navLeft[this.navActive] + "px";
			return style;
		},
		navStyle() {
			let data = {}
			if (this.scrollTop >= this.bannerH) data = {
				position: 'fixed',
				top: 0,
				left: 0,
				zIndex: 100
			}
			else data = {}
			return data
		}
	},
	data() {
		return {
			scrollTop: 0,
			navActive: null,
			navWidth: [],
			navLeft: [],
			article: [],
			industry: [],
			bannerH: 450,
			navH: 40,
			type: '',
			data: {}
		};
	},
	created() {
		axios.get('/website/api/website/article/list').then(res => {
			if (res.data.code == 200) {
				const data = res.data.data;
				const article = [];
				const industry = [];
				data.forEach(item => {
					if(item.type == 'article') article.push(item)
					else if (item.type == 'industry') industry.push(item)
				})
				this.article = article;
				this.industry = industry;
			}
		})
	},
	mounted() {
		this.bannerH = this.$refs.banner.offsetHeight;
		this.navH = this.$refs.nav.offsetHeight;
		window.addEventListener("scroll", this.getScroll);
		this.navWidth = [
			this.$refs.nav0.clientWidth,
			this.$refs.nav1.clientWidth,
		];
		this.navLeft = [this.$refs.nav0.offsetLeft, this.$refs.nav1.offsetLeft];
		if(this.$route.params.index) {
			this.navActive = this.$route.params.index;
		} else {
			this.navActive = 0;
		}
		this.$nextTick(() => {
			this.getScroll();
		})
	},
	methods: {
		getScroll() {
			this.scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
		},
		navActiveChange(idx) {
			if (this.type) this.back();
			if (this.scrollTop > this.bannerH) document.body.scrollTop = document.documentElement.scrollTop = this.bannerH
			this.navActive = idx
		},
		toDetail(type, data) {
			this.type = type;
			this.data = data;
			document.body.scrollTop = document.documentElement.scrollTop = this.bannerH
		},
		back() {
			document.body.scrollTop = document.documentElement.scrollTop = this.bannerH
			this.type = '';
			this.data = {};
		}
	},
};
</script>

<style lang="less" scoped>
.banner {
	width: 1920px;
	height: 450px;
	background: url("./assets/banner.png") no-repeat;
	background-size: 1920px;
	position: relative;
	.content {
		padding-top: 155px;
		line-height: 1;
		text-align: left;
		color: #ffffff;
		.title {
			font-size: 50px;
			padding-bottom: 30px;
		}
		.info {
			font-size: 22px;
			padding-bottom: 53px;
		}
		.button {
			display: inline-block;
			font-size: 18px;
			padding: 15px 34px;
			background: #ff9000;
			border-radius: 4px;
			cursor: pointer;
		}
	}
}
.navBox {
	width: 100vw;
	background-color: white;
	box-shadow: 0px 6px 20px 0px rgba(142, 171, 201, 0.12);
	.nav {
		width: 1240px;
		height: 80px;
		margin: 0 auto;
		font-size: 16px;
		display: flex;
		position: relative;
		.title {
			padding-top: 33px;
			margin-right: 40px;
			cursor: pointer;
			transition: all 0.5s;
			&.active {
				color: #218aef;
			}
			&:hover {
				color: #218aef;
			}
		}
		.activebox {
			position: absolute;
			bottom: 0;
			left: 0;
			height: 3px;
			background: #218aef;
			min-width: 20px;
			transition: all 0.5s;
			text-align: center;
			div {
				display: inline-block;
				width: 55%;
				height: 20px;
				background: linear-gradient(to bottom, #ffffff, #d3ebff);
				transform: translateY(-20px);
			}
		}
	}
}
.main {
	width: 1240px;
	margin: 0px auto 100px;
	display: flex;
	flex-wrap: wrap;
	.card {
		width: 400px;
		height: 450px;
		margin: 0 20px 20px 0;
		background: #ffffff;
		box-shadow: 0px 8px 70px 0px rgba(142, 171, 201, 0.2);
		cursor: pointer;
		&:nth-of-type(3n) {
			margin: 0 0 20px 0;
		}
		.img {
			width: 400px;
			height: 226px;
			background: #d8e3ef;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.text {
			width: 340px;
			margin: 36px 30px 0;
			text-align: left;
			.title {
				font-size: 18px;
				font-weight: bold;
				line-height: 1.5;
				margin-bottom: 24px;
				display: -webkit-box;
				text-overflow: ellipsis;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}
			.info {
				font-size: 14px;
				color: #999999;
				line-height: 28px;
				display: -webkit-box;
				text-overflow: ellipsis;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
				overflow: hidden;
			}
		}
	}
}
.back {
	margin-top: 20px;
	text-align: left;
	margin-left: 10px;
	font-size: 14px;
	cursor: pointer;
	display: flex;
	align-items: center;
	&:hover {
		color: #218aef;
		.icon {
			fill: #218aef;
		}
	}
	.icon {
		margin-right: 2px;
	}
}
.articleBox {
	width: 1240px;
	margin: 0 auto 40px;
}
</style>
<style lang="less">
.articleDetail {
	padding: 20px 100px;
	h1 {
		font-size: 32px;
		font-weight: 700;
	}
	.author {
		font-size: 12px;
		line-height: 1;
	}
	.content {
		font-size: 18px;
		p {
			text-align: justify;
			text-indent: 40px;
			margin-bottom: 4px;
		}
	}
}
</style>
